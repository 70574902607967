<template>
  <v-container>
    <v-dialog width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn block color="primary" label="Check" v-bind="attrs" v-on="on">Check </v-btn>
      </template>
      <v-card max-width="617">
        <v-toolbar flat>
          <v-row>
            <v-col cols="2">
              <v-btn icon dark color="#111B21" @click="isDialogVisible = false">
                <v-icon> {{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-toolbar-title :class="'header'" class="text-center text-subtitle-1 font-weight-bold">
                Connect happyhouse.com to Happyhomes Chat
              </v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <!-- ini cuma agar dia ketengah -->
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
          <ol>
            <li>Go to your domain provider’s site and log in.</li>
            <li>Find happyhouse.com and go to its control panel or settings panel.</li>
            <li>
              In your domain settings, find the area where you manage or edit nameserver records.
              <ul style="list-style-type: disc">
                <li class="text-subtitle-2 font-weight-light">
                  This area could be called DNS settings, Nameservers, NS or similar. If you don't see it immediately,
                  try looking for a menu at the top or on the side of the page.
                </li>
                <li class="text-subtitle-2 font-weight-light">
                  Find your current nameservers. They look like this (dns5.register.com, dns6.register.com)
                </li>
              </ul>
            </li>
            <li>
              On your domain provider's site, replace your current nameservers with the Marketa nameservers below, one
              at a time.
              <ul style="list-style-type: none">
                <li style="margin-left: -25px">
                  <p class="my-2 text--disabled text-subtitle-2 font-weight-light">
                    dns5.register.com --> ns11.marketadns.net
                  </p>
                </li>
                <li style="margin-left: -25px">
                  <p class="my-2 text--disabled text-subtitle-2 font-weight-light">
                    dns6.register.com --> ns10.marketadns.net
                  </p>
                </li>
              </ul>
            </li>
          </ol>
        </v-container>
        <v-divider></v-divider>
        <v-row>
          <v-col class="d-flex justify-end"> <CheckDomainStatusDialog> </CheckDomainStatusDialog> </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mdiClose } from '@mdi/js'
import CheckDomainStatusDialog from './CheckDomainStatus.vue'
export default {
  name: 'ConnectedToMarketa',
  components: { CheckDomainStatusDialog },
  data() {
    return {
      isDialogVisible: 'false',
      tableItems: [{ name: 'happiehome.org' }, { name: 'happiehome.net' }, { name: 'happiehome.store' }],
    }
  },
  setup() {
    return {
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style scoped>
* {
  font-weight: 400;
}

li {
  margin: 24px 0;
}
</style>
